import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uid:"",
  bio:"",
  productSold:0,
  reviews:0,
  listing:0,
  followers:0,
  followings:0,
  balance:0.0,
  category:"",
  bottomsSizes:[],
  brands:[],
  conditions:[],
  shoesSizes:[],
  topsSizes:[],
  discountCoupon:[],



};

const ProfileModel = createSlice({
  name: 'ProfileModel',
  initialState,
  reducers: {
    

},

});

// export const { } =
// UserModel.actions;

export default ProfileModel.reducer;
