import { createSlice } from '@reduxjs/toolkit';
const initialState = {

  isBag: false,
  isShoes: false,
  isAccessory: false,
  isClothing: false,
  isWatch: false,
  isJewellery: false
};


const BrandsSlice = createSlice({
  name: 'allBrands',
  initialState,
  reducers: {

    IsBag: (state, { payload }) => {
   state.isBag=payload
  },

  IsShoes: (state, { payload }) => {
    state.isShoes=payload

   },
   IsAccessory: (state, { payload }) => {
    state.isAccessory=payload

   },
   IsClothing: (state, { payload }) => {
    state.isClothing=payload
   },
   IsWatch: (state, { payload }) => {
    state.isWatch=payload

   },

   IsJewellery: (state, { payload }) => {
    state.isJewellery=payload

   },

},
  extraReducers: {

  },
});

export const { IsBag,IsAccessory,IsClothing,IsJewellery,IsShoes,IsWatch } =
BrandsSlice.actions;

export default BrandsSlice.reducer;
