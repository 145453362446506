import { createSlice } from '@reduxjs/toolkit';

const initialState = {

};

const UserProfile = createSlice({
  name: 'UserProfile',
  initialState,
  reducers: {

},

});


export default UserProfile.reducer;
