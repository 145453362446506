import { db, collection, getDocs }from '../../utils/firebase/firebase.utils';
export const getAllTypesThunk = async (_, thunkAPI) => {
  try {
    const colRef = collection(db, 'typeCategories');
    const data = [];

    const querySnapshot = await getDocs(colRef);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
  
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.message);
  }
};