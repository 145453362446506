import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootReducer } from "./root-reducer";
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

const persistConfig = {
  key: "root",
  storage,
  //stateReconciler: hardSet,
 // defaultState:{orders:[]},
 //blacklist: ['topsearches'] // navigation will not be persisted
 //whitelist: ['navigation'] // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});

export const persistor = persistStore(store);
