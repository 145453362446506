import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  frontView:"",
  BackView:'',
  SideView:"",
  ModelView:"",
  isWatches: false,
  isAccessories:false,

};


const ImagesSlice = createSlice({
  name: 'allImages',
  initialState,
  reducers: {

  getImgFrontClothes : (state, { payload }) => {
   state.frontView=payload
  },
  getImgBackClothes : (state, { payload }) => {
    state.BackView=payload
   },

   getImgSideViewClothes : (state, { payload }) => {
    state.SideView=payload
   },
   getImgModelClothes : (state, { payload }) => {
    state.ModelView=payload
   },
   getisWatches : (state, { payload }) => {
    state.isWatches=payload
   },
   getisAccessories: (state, { payload }) => {
    state.isAccessories=payload
   },
},
  extraReducers: {

  },
});

export const { getImgFrontClothes,getImgBackClothes ,getImgSideViewClothes,getImgModelClothes,getisWatches,getisAccessories} =
ImagesSlice.actions;

export default ImagesSlice.reducer;
