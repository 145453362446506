import React, {useState} from 'react';
import arrowdown from "./assets/images/arrowdown.svg";
import appstore from "./assets/images/arrowdown.png";

const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)
  const [visibledown, setVisibledown] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 900){
      setVisible(true)
    } 
    else if (scrolled <= 900){
      setVisible(false)
    }
  };
  const toggleVisibleDown = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 0){ 
        setVisibledown(false) 
    }  
    else if (scrolled <= 0){ 
        setVisibledown(true) 
    } 
  }; 
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  const scrollToBottom = () =>{ 
    window.scrollTo({ 
      top: document.documentElement.scrollHeight, 
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 
  window.addEventListener('scroll', toggleVisible);
  window.addEventListener('scroll', toggleVisibleDown);

  return (
    <>
    <div style={{display: visible ? 'inline' : 'none'}} >
     <img src={arrowdown} onClick={scrollToTop} className='btnscrolltop' 
     style={{display: visible ? 'inline' : 'none'}} />
    </div>
    {/* <button>
     <img src={appstore} onClick={scrollToBottom} className='btnscrolltop px-5' 
       style={{display: visibledown ? 'inline' : 'none'}} />
    </button> */}
    </>
  );
}
  
export default ScrollButton;