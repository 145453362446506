import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prices: {},
  totalPrices: {},
  authPrices: {},
  myBag: [],
  prodbag:[]
};

const PricesSlice = createSlice({
  name: "allprices",
  initialState,
  reducers: {
    SetPrices: (state, { payload }) => {
      state.prices = payload;
    },
    SetBagProd: (state, { payload }) => {
      state.prodbag = payload;
    },
    
    SetTotalAuthPrices: (state, { payload }) => {
      state.authPrices = payload;
    },

    SetTotalPrices: (state, { payload }) => {
      state.totalPrices = payload;
    },
    SetMyBag: (state, { payload }) => {
      state.myBag = payload;
    },
  },
});

export const { SetBagProd,SetPrices, SetTotalAuthPrices, SetTotalPrices, SetMyBag } =
  PricesSlice.actions;

export default PricesSlice.reducer;
