// import {initialState} from '../../InitialState/type_category'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {getAllTypesThunk} from './type_categoryThunk';


export const getAllTypes = createAsyncThunk(
  'allTypes/getAllTypes',
  getAllTypesThunk
);
const initialState={
  id:"",
name:"",
nameAr:"",
types:[],
}
const type_category = createSlice({
  name: 'typecategory',
  initialState,
  reducers: {
    
    
},
extraReducers: {
  [getAllTypes.pending]: (state) => {
    // state.isLoading = true;
  },
  [getAllTypes.fulfilled]: (state, { payload }) => {
    const types=payload
    state.types=payload
 
  
},
  [getAllTypes.rejected]: (state, { payload }) => {
    // state.isLoading = false;
    toast.error(payload);
  },
}

});



// export const { } =
// type_category.actions;
export default type_category.reducer;
