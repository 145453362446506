import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  idTypeCategory:"",
  idBrand:"",
  isCategory:""

};


const ModelsSilce = createSlice({
  name: 'allModels',
  initialState,
  reducers: {


  getIdType: (state, { payload }) => {
    state.idTypeCategory=payload

   },
   getIdBrand: (state, { payload }) => {
    state.idBrand=payload

   },
   getIsCategory: (state, { payload }) => {
    state.isCategory=payload

   },

},
  extraReducers: {

  },
});

export const { getIdSub,getIdType,getIdBrand,getIsCategory } =
ModelsSilce.actions;

export default ModelsSilce.reducer;
