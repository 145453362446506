import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
 
    productsViewed:[],
    recentlyViewedarr:[]
     
};


const RecentlyViewed = createSlice({
  name: 'recentlyViewed',
  initialState,
  reducers: {
    GetAllProductViewd: (state, { payload }) => {
      state.productsViewed=payload;
  
    },
   
  
   
},
  
});

export const { GetAllProductViewd } =
RecentlyViewed.actions;

export default RecentlyViewed.reducer;
