export const initialState={
    uid:"",
    email:"",
    firstName:"",
    lastName:"",
    phoneNumber:"",
    photoURL:"",
    photoURL:"",
    username:"",
    deactivated:"",
    isBlocked:"",
    dateDeactivated:"",
    fcmToken:"",
    userStatus:"",
  
}